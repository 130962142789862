export function waitUntil(condition) {
    const checker = r=>{
        if (condition()) r();
        else window.requestAnimationFrame(()=>checker(r));
    };
    return new Promise(r=>checker(r));
}

// From https://stackoverflow.com/a/18650828
export function formatBytes (bytes, decimals = 2) {
    if (bytes == 0) return 0;
    return parseFloat(bytes / 1000000).toFixed(decimals);
    // if (bytes === 0) return '0B';
    // const k = 1024;
    // const dm = decimals < 0 ? 0 : decimals;
    // const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    // const i = Math.floor(Math.log(bytes) / Math.log(k));
    // return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
};

export const extensionToMime = {
    "aac": "audio/aac",
    "abw": "application/x-abiword",
    "arc": "application/x-freearc",
    "avi": "video/x-msvideo",
    "azw": "application/vnd.amazon.ebook",
    "bin": "application/octet-stream",
    "bmp": "image/bmp",
    "bz": "application/x-bzip",
    "bz2": "application/x-bzip2",
    "csh": "application/x-csh",
    "css": "text/css",
    "csv": "text/csv",
    "doc": "application/msword",
    "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "eot": "application/vnd.ms-fontobject",
    "epub": "application/epub+zip",
    "gz": "application/gzip",
    "gif": "image/gif",
    "htm": "text/html",
    "html": "text/html",
    "ico": "image/vnd.microsoft.icon",
    "ics": "text/calendar",
    "jar": "application/java-archive",
    "jpeg": "image/jpeg",
    "jpg": "image/jpeg",
    "js": "application/x-javascript",
    "json": "application/json",
    "jsonld": "application/ld+json",
    "m4a": "audio/x-m4a",
    "mid": "audio/midi",
    "midi": "audio/midi",
    "mjs": "application/x-javascript",
    "mp3": "audio/mpeg",
    "mp4": "video/mpeg",
    "mpeg": "video/mpeg",
    "mpg": "video/mpeg",
    "mpkg": "application/vnd.apple.installer+xml",
    "mov": "video/quicktime",
    "odp": "application/vnd.oasis.opendocument.presentation",
    "ods": "application/vnd.oasis.opendocument.spreadsheet",
    "odt": "application/vnd.oasis.opendocument.text",
    "oga": "audio/ogg",
    "ogg": "audio/ogg",
    "ogv": "video/ogg",
    "ogx": "application/ogg",
    "opus": "audio/opus",
    "otf": "font/otf",
    "png": "image/png",
    "pdf": "application/pdf",
    "php": "application/php",
    "ppt": "application/vnd.ms-powerpoint",
    "pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "rar": "application/vnd.rar",
    "rtf": "application/rtf",
    "sh": "application/x-sh",
    "svg": "image/svg+xml",
    "swf": "application/x-shockwave-flash",
    "tar": "application/x-tar",
    "tif": "image/tiff",
    "tiff": "image/tiff",
    "ts": "video/mp2t",
    "tsv": "text/tsv",
    "ttf": "font/ttf",
    "txt": "text/plain",
    "vsd": "application/vnd.visio",
    "wav": "audio/wav",
    "weba": "audio/webm",
    "webm": "video/webm",
    "webp": "image/webp",
    "wma": "audio/webm",
    "wmv": "video/x-ms-wvm",
    "woff": "font/woff",
    "woff2": "font/woff2",
    "xhtml": "application/xhtml+xml",
    "xls": "application/vnd.ms-excel",
    "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "xml": "application/xml ",
    "xul": "application/vnd.mozilla.xul+xml",
    "zip": "application/zip",
    "3gp": "video/3gpp",
    "3g2": "video/3gpp2",
    "7z": "application/x-7z-compressed"
};

export function safetext(text){
	const table = {
		'<': 'lt',
		'>': 'gt',
		'"': 'quot',
		'\'': 'apos',
		'&': 'amp',
		'\r': '#10',
		'\n': '#13'
	};
	
	return text.toString().replace(/[<>"'\r\n&]/g, function(chr){
		return '&' + table[chr] + ';';
	});
};

export function setFocus(n,dontSelect) {
    n.focus();
    const textNode = [...n.childNodes].find(n=>n instanceof Text);
    if (!dontSelect && textNode && textNode.length){
      const content = textNode.textContent;
      const range = new Range();
      range.setStart(textNode,0);
      const ext = content.search(/\.[^.]+$/);
      if (ext>=0) range.setEnd(textNode,ext);
      else range.setEnd(textNode,content.length);
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(range);
    }
  }
